import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Graph = () => (
  
  <section id="graph">
    <Container>
      <Row>
        <Col>
          <div
            className="seo-h1 text-center text-lowercase mt-0 mb-5"
          >
            <h1 className="m-0">
              OK, Was soll ich jetzt machen?
            </h1>
          </div>
          <ol className="graph">
            <li className="graph-item">
              <span>1</span>
              <div>
              Wir werden Ihre Nachricht lesen, danke
              </div>
            </li>
            <li className="graph-item">
              <span>2</span>
              <div>
              Wir antworten innerhalb<br/>
              von sechs Stunden
              </div>
            </li>
            <li className="graph-item">
              <span>3</span>
              <div>
              Wir arrangieren für Sie ein halbstündiges Treffen mit einem unserer Experten
              </div>
            </li>
            <li className="graph-item">
              <span>4</span>
              <div>
              Sie erhalten ein Angebot innerhalb von fünf Arbeitstagen
              </div>
            </li>
            <li className="graph-item">
              <span>5</span>
              <div>
              Ist alles gut gelaufen? Wir starten 
              </div>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Graph
