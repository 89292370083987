import React from 'react'
import Link from '../common/Link'
import Img from 'gatsby-image'
import Video from '../common/Video'
import Slogan from './Slogan'
import ClutchLogo from '../../assets/images/logos/clutch-logo.png';

const Intro = ({ bgImage, video }) => (
  <section id="intro" className="jumbotron bg-dark">
    <div id="video-fallback" className="background">
      <Img resolutions={bgImage} alt="intro" className="background img-fluid intro-image-hires" />
      <Video src={video} />
    </div>
    <div className="container">
      <div className="row relative">
        <div className="col-xl-7 col-lg-8 col-md-10 col-sm-12">
          <Slogan />
          <h2 className="intro-description seo-lead">
            <strong>
            Wir sind ein prozessorientiertes Softwareentwicklungsunternehmen aus Polen.
            </strong>{' '}
            Wir helfen Unternehmen dabei, sich mit ihren Empfängern durch High-End-Mobil- und Webapps in Kontakt zu treten.
          </h2>
          <div className="d-flex flex-column flex-sm-row align-items-sm-center">
            <p className="mb-0">
              <Link
                to="/estimate-app-cost/"
                className="btn btn-outline-warning animate-scroll btn-lg estimate-btn"
                style={{ fontWeight: 700, fontSize: '20px' }}
                role="button"
              >
                Rechner für Apps
              </Link>
            </p>
            <img
              src={ClutchLogo}
              className="clutch-logo mt-5 mt-sm-0"
              alt="clutch-logo"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Intro
